import React from "react";
import {makeStyles, Button} from "@material-ui/core";
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: "rgb(50, 54, 57)"
  },
  flexGrow: {
    flexGrow: 1
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  logo: {
    color: "#ffffff",
  },
}));

const Navigation = ({handleLogin}) => {
  const classes = useStyles();

  return (
    <>
        <Button
            className={classes.button}
            color="inherit"
            component={Link}
            to={`/`}
        >
            Home
        </Button>
        <Button
            className={classes.button}
            color="inherit"
            component={Link}
            to={`/support`}
        >
            Support
        </Button>
      <Button
        className={classes.button}
        color="inherit"
        component={Link}
        to={"/login"}
      >
        Login
      </Button>
    </>
  );
};

export default Navigation;
