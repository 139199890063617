import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Drawer, Divider, Paper, Avatar, Typography, Button, Collapse, ListItem} from '@material-ui/core';
import {Hidden} from '@material-ui/core';

import useRouter from 'utils/useRouter';
import {Navigation} from 'components';
import navigationConfig from './navigationConfig';
import adminNavigationConfig from "./adminNavigationConfig";
import userNavigationConfig from "./userNavigationConfig";
import {GlobalContext} from "../../../../contexts/context";
import PowerOffIcon from "@material-ui/icons/PowerOffOutlined";
import PersonIcon from "@material-ui/icons/PersonOutline";
import {AdminNavigation, UserNavigation} from "../TopBar/components";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        overflowY: 'auto'
    },
    content: {
        padding: theme.spacing(2),
        overflow: "hidden",
    },
    profile: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 'fit-content'
    },
    avatar: {
        width: 60,
        height: 60
    },
    name: {
        marginTop: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(2)
    },
    navigation: {
        marginTop: theme.spacing(2)
    }
}));

const NavBar = ({openMobile, onMobileClose, className, ...rest}) => {
    const classes = useStyles();
    const context = useContext(GlobalContext);
    const router = useRouter();

    const [navigation, setNavigation] = useState(navigationConfig);

    useEffect(() => {
        if (openMobile) {
            onMobileClose && onMobileClose();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.location.pathname]);

    const handleLogout = async () => {
        await context.signOutUser();
        router.history.push('/login');
    };

    const fetchNavigation = () => {
        if (context.isAdmin)
            setNavigation(adminNavigationConfig);
        else if (!context.isAdmin && context.email && context.email !== "")
            setNavigation(userNavigationConfig);
        else
            setNavigation(navigationConfig);
    };

    useEffect(() => {
        fetchNavigation();
    }, [context]);

    let paddingLeft = 8;
    const style = {
        paddingLeft
    };

    const navbarContent = (
        <div className={classes.content}>
            {
                context.email && context.email !== "" ? (
                    <>
                        <div className={classes.profile}>
                            <Typography
                                className={classes.name}
                                variant="h4"
                            >
                                {context.email}
                            </Typography>
                        </div>
                    </>
                ) : null
            }
            {
                context.email && context.email !== "" ?
                    <ListItem
                        {...rest}
                        className={clsx(classes.item, className)}
                        disableGutters
                        style={style}
                    >
                        <Button
                            className={classes.button}
                            onClick={handleLogout}
                        >
                            <PowerOffIcon/>
                            &nbsp;{"Sign Out"}
                        </Button>
                    </ListItem> :
                    <ListItem
                        {...rest}
                        className={clsx(classes.item, className)}
                        disableGutters
                        style={style}
                    >
                        <Button
                            className={classes.button}
                            component={RouterLink}
                            to={"/login"}
                        >
                            <PersonIcon/>
                            &nbsp;{"Log In"}
                        </Button>
                    </ListItem>
            }
            <Divider className={classes.divider}/>
            <nav className={classes.navigation}>
                {navigation.map(list => (
                    <Navigation
                        component="div"
                        key={list.title}
                        pages={list.pages}
                        title={list.title}
                    />
                ))}
                {context.isAdmin ? userNavigationConfig.map(list => (
                    <div key={list.title}>
                        <Typography
                            variant={"subtitle1"}
                        >
                            User Navigation
                        </Typography>
                        <Navigation
                            component="div"
                            pages={list.pages}
                            title={list.title}
                        />
                    </div>
                )) : null}
            </nav>
        </div>
    );

    return (
        <Fragment>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    <div
                        {...rest}
                        className={clsx(classes.root, className)}
                    >
                        {navbarContent}
                    </div>
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Paper
                    {...rest}
                    className={clsx(classes.root, className)}
                    elevation={1}
                    square
                >
                    {navbarContent}
                </Paper>
            </Hidden>
        </Fragment>
    );
};

NavBar.propTypes = {
    className: PropTypes.string,
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

export default NavBar;
