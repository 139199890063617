import {
    USER_LOAD_PROFILE,
    USER_SIGN_IN,
    USER_SIGN_OUT,
    SAVE_FORM_VALUES,
    USER_STATE_SAVE,
} from "../actions";
import initState from "../contexts/initState";

const sign_in_user = (state, {result: localState}) => {
    return {
        ...state,
        email: localState.email || initState.email,
        fullName: localState.fullName || initState.fullName,
        userId: localState.userId || initState.userId,
        userName: localState.userName || initState.fullName,
        isAdmin: localState.roles.includes("Administrator"),
    };
};

const load_user_profile = (state, {localState}) => {
    if (localState.user)
        return {
            ...state,
            email: localState.user.email || initState.email,
            fullName: localState.user.fullName || initState.fullName,
            userName: localState.user.userName || initState.userName,
            userId: localState.user.userId || initState.userId,
            isAdmin: localState.user.roles.includes("Administrator"),
            filters: localState.filters || null,
        };
    else
        return {
            ...initState,
        }
};

const save_user_state = (state, {node, data}) => {
    return {
        ...state,
        [node]: data,
    };
};

const sign_out_user = (state, {}) => {
    return {
        ...initState,
    };
};

export default (state, action) => {
    switch (action.type) {
        case USER_LOAD_PROFILE:
            return load_user_profile(state, action);
        case USER_SIGN_OUT:
            return sign_out_user(state, action);
        case USER_SIGN_IN:
            return sign_in_user(state, action);
        case USER_STATE_SAVE:
            return save_user_state(state, action);
        case SAVE_FORM_VALUES:
            return {...state, ...action};
        default:
            return state;
    }
};
