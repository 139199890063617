import React, {useContext, useEffect, useState} from 'react';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import MomentUtils from '@date-io/moment';
import {Provider as StoreProvider} from 'react-redux';
import {ThemeProvider} from '@material-ui/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {renderRoutes} from 'react-router-config';

import theme from './theme';
import routes from './routes';
import {
    ScrollReset,
    GoogleAnalytics,
    CookiesNotification
} from './components';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/index.scss';
import {GlobalContext} from "./contexts/context";
import {
    makeStyles,
    CircularProgress,
    Grid,
    Typography,
} from "@material-ui/core";

const history = createBrowserHistory();

const useStyles = makeStyles(() => ({
    progress: {
        height: "100vh",
    },
}));

const App = () => {
    const classes = useStyles();
    const context = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);

    const loadSession = async () => {
        try {
            await context.loadUserSession();
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadSession();
    }, []);

    useEffect(() => {
    }, [context]);

    return (
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Router history={history}>
                    <ScrollReset/>
                    <GoogleAnalytics/>
                    {!loading ? renderRoutes(routes) : (
                        <>
                            <Grid className={classes.progress}
                                  container
                                  direction={"column"}
                                  justify={"center"}
                                  alignItems={"center"}>
                                <Typography variant={"h2"}
                                            style={{marginBottom: 50}}>
                                    Initializing App...
                                </Typography>
                                <CircularProgress size={150}/>
                            </Grid>
                        </>
                    )}
                </Router>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};

export default App;
