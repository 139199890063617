import React, {createContext, useReducer} from "react";
import userProfileReducer from "../reducers/userProfileReducer";
import {
    USER_SIGN_OUT,
    USER_SIGN_IN,
    USER_LOAD_PROFILE,
    USER_STATE_SAVE,
} from "../actions";
import {login, checkToken} from "../services/auth-service";
import initState from "./initState";
import {getLocalStorage, getLocalStorageNode, setLocalStorage, setLocalStorageNode} from "../services/local_storage";

export const GlobalContext = createContext({
    ...initState,
    signInUser: () => {
    },
    loadUserSession: () => {
    },
    signOutUser: () => {
    },
    saveLocalState: () => {
    },
});

const GlobalState = (props) => {
    const [userState, dispatch] = useReducer(userProfileReducer, {...initState});

    const signInUser = async (form) => {
        const result = await login(form.email, form.password);

        if (!result.error)
            dispatch({type: USER_SIGN_IN, result});
        else
            return {
                error: result.error,
            }
    };

    const signOutUser = async () => {
        try {
            await setLocalStorage({user: "", token: ""});
            dispatch({type: USER_SIGN_OUT});
        } catch (error) {
            return {
                error,
            };
        }
    };

    const loadUserSession = async () => {
        await checkToken();
        const localState = await getLocalStorage();

        if (localState && Object.keys(localState).length > 0)
            dispatch({type: USER_LOAD_PROFILE, localState})
    };

    const saveLocalState = async (node, data) => {
        const localState = await setLocalStorageNode(node, data);

        if (localState)
            dispatch({type: USER_STATE_SAVE, node, data})
    };

    return (
        <GlobalContext.Provider
            value={{
                ...userState,
                signInUser,
                loadUserSession,
                signOutUser,
                saveLocalState,
            }}>
            {props.children}
        </GlobalContext.Provider>
    );
};

export default GlobalState;
