import React, {Suspense, useState} from 'react';
import {renderRoutes} from 'react-router-config';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {LinearProgress} from '@material-ui/core';

import {NavBar, TopBar, Footer} from './components';
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
    },
    topBar: {
        zIndex: 2,
        position: 'relative'
    },
    container: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            height: 'auto'
        }
    },
    navBar: {
        zIndex: 3,
        width: 256,
        minWidth: 256,
        flex: '0 0 auto'
    },
    content: {
        overflow: 'hidden',
        overflowY: 'auto',
        flex: '1 1 auto',
    },
    footer: {
        zIndex: 4,
        position: "fixed",
        bottom: 0,
        padding: 5,
        backgroundColor: theme.palette.primary.main,
        width: "100%",
    },
}));

const Dashboard = props => {
    const {route} = props;

    const classes = useStyles();
    const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

    const handleNavBarMobileOpen = () => {
        setOpenNavBarMobile(true);
    };

    const handleNavBarMobileClose = () => {
        setOpenNavBarMobile(false);
    };

    return (
        <div className={classes.root}>
            <TopBar
                className={classes.topBar}
                onOpenNavBarMobile={handleNavBarMobileOpen}
            />
            <div className={classes.container}>
                <Hidden mdUp>
                    <NavBar
                        className={classes.navBar}
                        onMobileClose={handleNavBarMobileClose}
                        openMobile={openNavBarMobile}
                    />
                </Hidden>
                <main className={classes.content}>
                    <Suspense fallback={<LinearProgress/>}>
                        {renderRoutes(route.routes)}
                    </Suspense>
                </main>
            </div>
            <Footer
                className={classes.footer}/>
        </div>
    );
};

Dashboard.propTypes = {
    route: PropTypes.object
};

export default Dashboard;
