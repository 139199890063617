export const setLocalStorage = async (value) => {
    const currentState = getLocalStorage();
    const newState = {
        ...currentState,
        ...value,
    };
    localStorage.setItem(`ssp_state`, JSON.stringify(newState));
    return await getLocalStorage();
};

export const setLocalStorageNode = async (node, value) => {
    const currentState = await getLocalStorage();
    const newState = {
        ...currentState,
        [node]: {
            ...currentState[node],
            ...value,
        },
    };
    localStorage.setItem(`ssp_state`, JSON.stringify(newState));
    return await getLocalStorage();
};

export const getLocalStorage = async () => {
    const data = localStorage.getItem(`ssp_state`);

    if (data === null)
        localStorage.setItem(`ssp_state`, '{}');

    return JSON.parse(data);
};

export const getLocalStorageNode = async (node) => {
    let data = localStorage.getItem(`ssp_state`);

    if (data === null)
        localStorage.setItem(`ssp_state`, '{}');

    data = JSON.parse(data);

    if (!data)
        return '';

    return data[node];
};
