/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import CardGiftCard from "@material-ui/icons/CardGiftcardOutlined";
import HelpIcon from "@material-ui/icons/HelpOutlined";
import PersonIcon from "@material-ui/icons/PersonOutline";


import {Label} from 'components';

export default [
    {
        title: 'Pages',
        pages: [
            {
                title: 'Home',
                href: '/',
                icon: HomeIcon
            },
            {
                title: 'My Account',
                href: '/myAccount',
                icon: PersonIcon
            },
            {
                title: 'Buy Credits',
                href: '/purchase',
                icon: CardGiftCard
            },
            {
                title: 'Support',
                href: '/support',
                icon: HelpIcon
            },
        ],
    }
];
