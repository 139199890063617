import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Typography, Grid, Button} from '@material-ui/core';
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles(theme => ({
        root: {
            backgroundColor: theme.palette.white
        },
        header: {
            maxWidth: '100%',
            margin: '0 auto',
            padding: '100px 24px',
            height: "60vh",
            [theme.breakpoints.down('lg')]: {
                padding: '40px 24px',
                height: "60vh",
            },
            [theme.breakpoints.down('sm')]: {
                padding: '40px 24px',
                height: "40vh",
            },
        },
        buttons: {
            marginTop: theme.spacing(3),
            display: 'flex',
            justifyContent: 'center'
        },
        mediaContainer: {
            margin: '0 auto',
            maxWidth: 1600,
            padding: theme.spacing(0, 2),
            overflow: 'hidden'
        },
        media: {
            width: '100%'
        },
        stats: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            padding: theme.spacing(1)
        },
        statsInner: {
            width: theme.breakpoints.values.md,
            maxWidth: '100%',
            margin: '0 auto'
        },
        button: {
            color: "#ffffff",
        },
        information: {
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: 20,
        },
        overlay: {
            backgroundImage: 'url(https://source.unsplash.com/1600x900/?vacation,beach)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: "60vh",
            [theme.breakpoints.down('md')]: {
                height: "50vh",
            }
        },
        colorOverlay: {
            backgroundColor: 'rgba(0,0,0,0.25)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: "60vh",
            [theme.breakpoints.down('md')]: {
                height: "50vh",
            }
        },
        bodyContent: {
            padding: "40px 20px",
        },
        subtitle: {
            fontWeight: "bold",
            marginBottom: 25,
        },
        paragraph: {
            marginBottom: 25,
        },
        headline: {
            color: "#ffffff",
            fontFamily: "'Roboto Condensed', sans-serif",
            marginBottom: 50,
        },
        subheadline: {
            color: "#ffffff",
            fontFamily: "'Roboto Condensed', sans-serif",
            marginBottom: 50,
        },
        callToAction: {
            color: "#ffffff",
            fontWeight: "bold",
            marginBottom: 50,
            fontFamily: "'Roboto Condensed', sans-serif",
            border: "3px solid #f79030",
            padding: "4px 12px"
        },
        downButton: {
            color: "#ffffff",
        },
        sectionTitle: {
            fontFamily: "'Roboto Condensed', sans-serif",
            fontWeight: "bold",
            color: "rgba(0, 0, 0, 0.87)",
            marginTop: 50,
        },
        buttonContainer: {
            margin: "10%",
            [theme.breakpoints.down('xs')]: {
                width: "100%",
                margin: "2%",
            }
        },
        buttonImage: {
            width: 200,
            [theme.breakpoints.down('xs')]: {
                width: "100%",
            }
        },
        videoContainer: {
            width: 950,
            height: 534,
            [theme.breakpoints.down('md')]: {
                width: "87vw",
                height: "30vh",
                margin: "0 auto",
            }
        },
        headerContainer: {
            textAlign: "center",
            marginTop: "75px",
            [theme.breakpoints.down('md')]: {
                marginTop: 0,
            }
        },
        logo: {
            color: "#ffffff",
            fontSize: "4em",
            lineHeight: 1.5,
            [theme.breakpoints.down('lg')]: {
                fontSize: "3.6em",
                lineHeight: 1.2,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: "4em",
                lineHeight: 1.5,
            },
        },
        subLogo: {
            color: "#ffffff",
            fontSize: "2em",
            lineHeight: 1.5,
            fontStyle: "italic",
            [theme.breakpoints.down('lg')]: {
                fontSize: "1.5em",
                lineHeight: 1.2,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: "2em",
                lineHeight: 1.5,
            },
        },
    }
));

const Header = props => {
    const {className, ...rest} = props;

    const classes = useStyles();

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Grid container
                  component="main"
                  className={classes.root}>
                <Grid item
                      xs={false}
                      sm={12}
                      className={classes.overlay}>
                    <div className={classes.colorOverlay}>
                        <Grid container>
                            <Hidden
                                smDown
                            >
                                <Grid item
                                      xs={3}>

                                </Grid>
                            </Hidden>
                            <Grid item
                                  md={6}
                                  xs={12}
                                  className={classes.headerContainer}
                            >
                                <Grid container
                                      style={{height: "50vh"}}
                                      justify={"space-evenly"}
                                      alignItems={"center"}
                                      direction={"column"}>
                                    <Grid item>
                                        <div className={classes.header}>

                                            <Hidden
                                                mdDown
                                            >
                                                <Typography
                                                    align="center"
                                                    gutterBottom
                                                    variant="h1"
                                                    className={classes.logo}
                                                >
                                                    SnapShot Postcard
                                                </Typography>
                                                <Typography
                                                    align="center"
                                                    component="h2"
                                                    variant="h4"
                                                    className={classes.subLogo}
                                                >
                                                    Share your photos as beautiful printed postcards, mailed directly from
                                                    your phone or tablet.
                                                </Typography>
                                            </Hidden>
                                            <Hidden
                                                lgUp
                                            >
                                                <Typography
                                                    align="center"
                                                    gutterBottom
                                                    variant="h2"
                                                    style={{color: "#ffffff"}}
                                                >
                                                    SnapShot Postcard
                                                </Typography>
                                                <Typography
                                                    align="center"
                                                    component="h2"
                                                    variant="subtitle1"
                                                    style={{color: "#ffffff"}}
                                                >
                                                    Share your photos as beautiful printed postcards, mailed directly from
                                                    your phone or tablet.
                                                </Typography>
                                            </Hidden>
                                            <div className={classes.buttons}>
                                                <div className={classes.buttonContainer}>
                                                    <Button
                                                        component="a"
                                                        href="http://www.snapshotpostcard.com/AppStore"
                                                        target="_blank"
                                                        variant="contained"
                                                        style={{
                                                            padding: 0,
                                                            borderRadius: 10,
                                                        }}
                                                    >
                                                        <img
                                                            className={classes.buttonImage}
                                                            src="/images/appleDownload.png"
                                                            alt="App Store download"
                                                        />
                                                    </Button>
                                                </div>
                                                <div className={classes.buttonContainer}>
                                                    <Button
                                                        component="a"
                                                        href="http://www.snapshotpostcard.com/Android"
                                                        target="_blank"
                                                        variant="contained"
                                                        style={{
                                                            padding: 0,
                                                            margin: 0,
                                                            borderRadius: 10,
                                                            backgroundColor: "#282828"
                                                        }}
                                                    >
                                                        <img
                                                            className={classes.buttonImage}
                                                            src="/images/androidDownload.png"
                                                            alt="Android download"
                                                        />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item
                                  xs={3}>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            {/*<div className={classes.mediaContainer}>*/}
            {/*<img*/}
            {/*alt="Demos"*/}
            {/*className={classes.media}*/}
            {/*src="/images/presentation/header.jpg"*/}
            {/*/>*/}
            {/*</div>*/}
            <div className={classes.stats}>
                <Grid
                    alignItems="center"
                    className={classes.statsInner}
                    container
                    justify="center"
                    spacing={3}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <iframe
                            className={classes.videoContainer}
                            src="https://www.youtube.com/embed/jM4wv2cchOI"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

Header.propTypes = {
    className: PropTypes.string
};

export default Header;
