import axios from "axios";
import config from "../config";
import {decodeToken} from "../services/jwt";
import {getLocalStorageNode, setLocalStorage} from "./local_storage";

export const login = async (username, password) => {
    try {
        const {data: {token}} = await axios.post(`${config.apiURL}accounts/login`, {username, password});
        const {claim: user} = decodeToken(token);
        await setLocalStorage({user, token});
        return user;
    } catch (err) {
        return {
            error: "Invalid username or password",
        }
    }
};

export const recoverPassword = async (email) => {
    try {
        return await axios.post(`${config.apiURL}accounts/resetPassword`, {email});
    } catch (err) {
        return {
            error: "Failed to recover password. Please contact support!",
        };
    }
};

export const checkToken = async () => {
    const date = new Date().getTime() / 1000;
    const token = await getToken();
    const {claim} = await decodeToken(token);

    if (claim && claim.exp && (Math.floor(date) > claim.exp)) {
        let {data: {token: newToken}} = await axios.post(`${config.apiURL}auth/validate`,
            {
                token,
            });

        const {claim: user} = decodeToken(newToken);
        return await setLocalStorage({token: newToken, user: {...user}});
    }
};

export const logout = async () => {
    await setLocalStorage({user: "", token: ""});
};

const getToken = async () => {
    return await getLocalStorageNode('token');
};
