import React, {useContext} from "react";
import {makeStyles, Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import {GlobalContext} from "../../../../../../contexts/context";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: "rgb(50, 54, 57)"
  },
  flexGrow: {
    flexGrow: 1
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  logo: {
    color: "#ffffff",
  },
}));

const UserNavigation = ({handleLogout}) => {
  const classes = useStyles();
  const context = useContext(GlobalContext);

  return (
    <>
        <Button
            className={classes.button}
            color="inherit"
            component={Link}
            to={`/`}
        >
            Home
        </Button>
        <Button
            className={classes.button}
            color="inherit"
            component={Link}
            to={`/myAccount`}
        >
            My Account
        </Button>
        <Button
            className={classes.button}
            color="inherit"
            component={Link}
            to={`/purchase`}
        >
            Buy Credits
        </Button>
        <Button
            className={classes.button}
            color="inherit"
            component={Link}
            to={`/support`}
        >
            Support
        </Button>
      <Button
        className={classes.button}
        color="inherit"
        onClick={handleLogout}
      >
        Sign out
      </Button>
    </>
  );
};

export default UserNavigation;
