/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import WorkIcon from "@material-ui/icons/WorkOutline";
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined";
import PersonIcon from "@material-ui/icons/PersonOutline";
import BusinessIcon from "@material-ui/icons/BusinessOutlined";
import CollectionsIcon from "@material-ui/icons/CollectionsOutlined";


import {Label} from 'components';

export default [
    {
        title: 'Pages',
        pages: [
            {
                title: 'Home',
                href: '/',
                icon: HomeIcon
            },
            {
                title: 'Orders',
                href: '/orders',
                icon: WorkIcon
            },
            {
                title: 'Transactions',
                href: '/transactions',
                icon: ReceiptIcon
            },
            {
                title: 'Accounts',
                href: '/accounts',
                icon: PersonIcon
            },
            {
                title: 'PAPA',
                href: '/organizations',
                icon: BusinessIcon
            },
            {
                title: 'Batches',
                href: '/batches',
                icon: CollectionsIcon
            },
        ],
    }
];
