/* eslint-disable no-unused-vars */
import React, {useContext, useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {
    AppBar,
    Button,
    IconButton,
    Toolbar,
    Hidden,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {Link} from "react-router-dom";
import useRouter from 'utils/useRouter';
import {PricingModal, NotificationsPopover} from 'components';
import {logout} from 'actions';
import Typography from "@material-ui/core/Typography";
import {GlobalContext} from "../../../../contexts/context";
import {
    AdminNavigation,
    Navigation,
    UserNavigation,
} from "./components"

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        backgroundColor: "#fcb229",
    },
    flexGrow: {
        flexGrow: 1
    },
    button: {
        marginLeft: theme.spacing(1)
    },
    logo: {
        color: "#ffffff",
    },
    title: {
        color: "#ffffff",
        textDecoration: "none",
        fontSize: 24,
        fontFamily: "'Roboto Condensed', sans-serif",
        lineHeight: "1.6",
        letterSpacing: 0,
        border: "3px solid #ffffff",
        padding: "0 12px",
    },
}));

const TopBar = ({onOpenNavBarMobile, className, ...rest}) => {
    const classes = useStyles();
    const context = useContext(GlobalContext);
    const {history} = useRouter();

    const handleLogout = async () => {
        await context.signOutUser();
        history.push('/login');
    };

    const fetchNavigation = () => {
        if (context.isAdmin)
            return (<AdminNavigation handleLogout={handleLogout}/>);
        else if (!context.isAdmin && context.email && context.email !== "")
            return (<UserNavigation handleLogout={handleLogout}/>);
        else
            return (<Navigation/>)
    };

    useEffect(() => {
        fetchNavigation();
    }, [context]);

    return (
        <AppBar
            {...rest}
            className={clsx(classes.root, className)}
            color="primary"
        >
            <Toolbar>
                <Typography variant="h6"
                            className={classes.title}
                            component={Link}
                            to="/">
                    SNAPSHOT POSTCARD
                </Typography>
                <div className={classes.flexGrow}/>
                <Hidden mdDown>
                    {
                        fetchNavigation()
                    }
                </Hidden>
                <Hidden lgUp>
                    <IconButton
                        color="inherit"
                        onClick={onOpenNavBarMobile}
                    >
                        <MenuIcon/>
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
