import React, {useContext} from "react";
import {makeStyles, Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {GlobalContext} from "../../../../../../contexts/context";

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        backgroundColor: "rgb(50, 54, 57)"
    },
    flexGrow: {
        flexGrow: 1
    },
    button: {
        marginLeft: theme.spacing(1)
    },
    logo: {
        color: "#ffffff",
    },
}));

const AdminNavigation = ({handleLogout}) => {
    const classes = useStyles();
    const context = useContext(GlobalContext);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                className={classes.button}
                color="inherit"
                component={Link}
                to="/orders"
            >
                Orders
            </Button>
            <Button
                className={classes.button}
                color="inherit"
                component={Link}
                to="/transactions"
            >
                Transactions
            </Button>
            <Button
                className={classes.button}
                color="inherit"
                component={Link}
                to="/accounts"
            >
                Accounts
            </Button>
            <Button
                className={classes.button}
                color="inherit"
                component={Link}
                to="/organizations"
            >
                PAPA
            </Button>
            <Button
                className={classes.button}
                color="inherit"
                component={Link}
                to="/batches"
            >
                Batches
            </Button>
            <Button className={classes.button}
                    color="inherit"
                    onClick={handleClick}>
                Admin
            </Button>
            <Menu id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}>
                <MenuItem component={Link}
                          to={`/myAccount`}
                          onClick={handleClose}>
                    My Account
                </MenuItem>
                <MenuItem component={Link}
                          to="/purchase"
                          onClick={handleClose}>
                    Buy Credits
                </MenuItem>
                <MenuItem component={Link}
                          to="/support"
                          onClick={handleClose}>
                    Support
                </MenuItem>
                <MenuItem onClick={handleLogout}
                          component={Link}
                          to="/">
                    Logout
                </MenuItem>
            </Menu>
        </>
    );
};

export default AdminNavigation;
